import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <p><a parentName="p" {...{
        "href": "https://github.com/graphql/graphql-spec/pull/649"
      }}>{`GraphQL supports a specification URL for custom Scalars`}</a>{` `}</p>
    <p>{`This page hosts community defined custom Scalars which can referenced via `}</p>
    <p><strong parentName="p">{` `}<a parentName="strong" {...{
          "href": "https://www.graphql-scalars.com/SPECIFICATION-NAME"
        }}>{`https://www.graphql-scalars.com/SPECIFICATION-NAME`}</a>{` `}</strong></p>
    <p />
    <p />
Please read <Link to="/scalars-guide" mdxType="Link">Reference guide to implement custom Scalars</Link> for a detail discussion about custom Scalars.
    <p />
    <h3 {...{
      "id": "list-of-custom-graphql-scalars-specifications",
      "style": {
        "position": "relative"
      }
    }}>{`List of custom GraphQL Scalars specifications`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Short Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Link to="/date-time/" mdxType="Link">{`DateTime`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A specific point in time without time zone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WIP`}</td>
        </tr>
      </tbody>
    </table>
    <p />
    <p>{`For questions and general feedback please open a `}<a parentName="p" {...{
        "href": "https://github.com/andimarek/graphql-scalars.com/issues"
      }}>{`GitHub Issue`}</a></p>
    <p>{`Made by `}<a parentName="p" {...{
        "href": "https://twitter.com/andimarek"
      }}>{`@andimarek`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      